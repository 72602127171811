import { rest } from 'msw';
import type { ServiceType } from 'common/types';

// these route handlers allow the ESPM status screens to work in demo environment
// otherwise, the espm-mapping route attempts to sync with ESPM, fails, and returns errors

const thisMorning = new Date();
thisMorning.setHours(8, 0, 0);

const excludedUtilityTypes = [] as ServiceType[];

export default [
    // 2600 N Kimball
    rest.get('/api/buildings/cBygf1LxzCaQN3GKPDtKa/espm-mapping', (_req, res, ctx) =>
        res(
            ctx.json({
                data: {
                    id: '5ys1ISpdQaq8kAKYyE4Vdh',
                    name: '2600 N Kimball',
                    externalId: '17651460',
                    address: {
                        address1: '2600 N Kimball Ave',
                        city: 'Chicago',
                        state: 'IL',
                        postalCode: '60647',
                        country: 'US',
                    },
                    status: 'active',
                    meters: [
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3T',
                            name: 'Aggregate Electric Meter #1',
                            externalId: '31415926',
                            status: 'active',
                            serviceType: 'electric',
                            unitOfMeasure: 'kWh',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3U',
                            name: 'Aggregate Gas Meter',
                            externalId: '31415927',
                            status: 'active',
                            serviceType: 'gas',
                            unitOfMeasure: 'thm',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3V',
                            name: 'Water Meter',
                            externalId: '31415928',
                            status: 'active',
                            serviceType: 'water',
                            unitOfMeasure: 'CCF',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                    ].filter((m) => !excludedUtilityTypes.includes(m.serviceType as ServiceType)),
                    buildingId: 'cBygf1LxzCaQN3GKPDtKa',
                    shareAccessLevel: 'readWrite',
                },
            })
        )
    ),
    rest.get('/api/buildings/cBygf1LxzCaQN3GKPDtKa/espm-sync-status', (_req, res, ctx) =>
        res(
            ctx.json({
                data: {
                    metrics: {
                        metadata: { year: 2022, month: 9 },
                        runAt: thisMorning.toISOString(),
                        status: 'success',
                        errors: [],
                    },
                    usage: {
                        numSyncMonths: 24,
                        syncs: [
                            {
                                id: '2AxsPK9YEh6wwY6k307U8c',
                                externalId: '31415926',
                                meterId: 'IxZ7BoOQGyuf5gcPU6b3T',
                                name: 'Aggregate Electric Meter #1',
                                status: 'success',
                                serviceType: 'electric',
                                runAt: thisMorning.toISOString(),
                            },
                            {
                                id: '2AxsPK9YEh6wwY6k307U8c',
                                externalId: '31415927',
                                meterId: 'IxZ7BoOQGyuf5gcPU6b3U',
                                name: 'Aggregate Gas Meter',
                                status: 'success',
                                serviceType: 'gas',
                                runAt: thisMorning.toISOString(),
                            },
                            {
                                id: '2AxsPK9YEh6wwY6k307U8c',
                                externalId: '31415928',
                                meterId: 'IxZ7BoOQGyuf5gcPU6b3V',
                                name: 'Water Meter',
                                status: 'success',
                                serviceType: 'water',
                                runAt: thisMorning.toISOString(),
                            },
                        ].filter(
                            (m) => !excludedUtilityTypes.includes(m.serviceType as ServiceType)
                        ),
                    },
                },
            })
        )
    ),
    // 1261 W Argyle
    rest.get('/api/buildings/uxzou8Hs7i9yqimM6vw6r/espm-mapping', (_req, res, ctx) =>
        res(
            ctx.json({
                data: {
                    id: '6ucVMlJS4ikfId1IJEv6Vg',
                    name: '1261 W Argyle',
                    externalId: '17651457',
                    address: {
                        address1: '1301 N Argyle St',
                        city: 'Chicago',
                        state: 'IL',
                        postalCode: '60640',
                        country: 'US',
                    },
                    status: 'pending',
                    meters: [
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3T',
                            name: 'Electric Meter #1',
                            externalId: '31415925',
                            status: 'active',
                            serviceType: 'electric',
                            unitOfMeasure: 'kWh',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3S',
                            name: 'Electric Meter #2',
                            externalId: '31415924',
                            status: 'active',
                            serviceType: 'electric',
                            unitOfMeasure: 'kWh',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3U',
                            name: 'Gas Meter',
                            externalId: '31415923',
                            status: 'active',
                            serviceType: 'gas',
                            unitOfMeasure: 'thm',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3V',
                            name: 'Water Meter',
                            externalId: '31415922',
                            status: 'active',
                            serviceType: 'water',
                            unitOfMeasure: 'CCF',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                    ].filter((m) => !excludedUtilityTypes.includes(m.serviceType as ServiceType)),
                    buildingId: 'uxzou8Hs7i9yqimM6vw6r',
                    shareAccessLevel: 'readWrite',
                },
            })
        )
    ),
    // 1111 West 22nd Street - no meters shared
    rest.get('/api/buildings/w5EHzN05AWcBRAL0lFIxp/espm-mapping', (_req, res, ctx) =>
        res(
            ctx.json({
                data: {
                    id: '6ucVMlJS4ikfId1IJEv6Vh',
                    name: '1111 West 22nd Street',
                    externalId: '17651457',
                    address: {
                        address1: '1111 West 22nd Street',
                        city: 'Oak Brook',
                        state: 'IL',
                        postalCode: '60523',
                        country: 'US',
                    },
                    status: 'pending',
                    meters: [],
                    buildingId: 'w5EHzN05AWcBRAL0lFIxp',
                    shareAccessLevel: 'readWrite',
                },
            })
        )
    ),
    // 181 West Madison, partially connected - confirm mapping
    rest.get('/api/buildings/3OSVpmcRGdedAubdqW4ubU/espm-mapping', (_req, res, ctx) =>
        res(
            ctx.json({
                data: {
                    id: '1MIkT7m8lWc4YqICMF0dYR',
                    name: '181 West Madison',
                    externalId: '17651459',
                    address: {
                        address1: '181 W Madison St',
                        city: 'Chicago',
                        state: 'IL',
                        postalCode: '60602',
                        country: 'US',
                    },
                    status: 'pending',
                    meters: [
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3T',
                            name: 'Electric Meter #1',
                            externalId: '31415925',
                            status: 'pending',
                            serviceType: 'electric',
                            unitOfMeasure: 'kWh',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3S',
                            name: 'Electric Meter #2',
                            externalId: '31415924',
                            status: 'pending',
                            serviceType: 'electric',
                            unitOfMeasure: 'kWh',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3U',
                            name: 'Gas Meter',
                            externalId: '31415923',
                            status: 'pending',
                            serviceType: 'gas',
                            unitOfMeasure: 'thm',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                        {
                            id: 'IxZ7BoOQGyuf5gcPU6b3V',
                            name: 'Water Meter',
                            externalId: '31415922',
                            status: 'pending',
                            serviceType: 'water',
                            unitOfMeasure: 'CCF',
                            shareAccessLevel: 'readWrite',
                            espmPropertyExternalId: '17651460',
                        },
                    ].filter((m) => !excludedUtilityTypes.includes(m.serviceType as ServiceType)),
                    buildingId: '3OSVpmcRGdedAubdqW4ubU',
                    shareAccessLevel: 'unknown',
                },
            })
        )
    ),
    // 300 North
    rest.get('/api/buildings/brNuuOssUdv4rGMZhdNxq/espm-mapping', (_req, res, ctx) =>
        res(ctx.status(404))
    ),
];
