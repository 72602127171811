import { rest } from 'msw';

// these handlers exist as a reference for API development for WBB-473

const mockUsers = {
    '2qc9g7b4hYEOxe7RdONnmn': {
        id: '2qc9g7b4hYEOxe7RdONnmn',
        externalMappingId: 'anagtTQWkUi6S8JURwCe6w',
        serviceProviderRoles: [],
        buildingIds: [],
        name: 'Aleeza Webb',
        companyName: null,
        emailAddress: 'aleeza.webb@calicoenergy.com',
        emailVerified: false,
        phoneNumber: null,
        lastLoginDate: null,
        lastAgreedToTermsDate: null,
        roles: ['operationalAdmin'],
        isEspmConnected: true, // NOTE: this is new field, this exists on GET /users and on GET /users/:userId
        espmPropertyCount: 15, // NOTE: this is new field, but *only* exists on GET /users/:userId (no need to query for it on listing, only on individual user details)
    },
    '2ZOIhSRUF3iXQRbM3i4kFz': {
        id: '2ZOIhSRUF3iXQRbM3i4kFz',
        externalMappingId: 'przyFc6JrUKC3cf1Yuan6Q',
        serviceProviderRoles: [],
        buildingIds: [],
        name: 'Andrea Klem',
        companyName: null,
        emailAddress: 'andrea.klem@calicoenergy.com',
        emailVerified: false,
        phoneNumber: null,
        lastLoginDate: null,
        lastAgreedToTermsDate: null,
        roles: ['operationalAdmin'],
        isEspmConnected: false,
        espmPropertyCount: 0,
    },
    '54s3Fq50IJvipjECWrEXJS': {
        id: '54s3Fq50IJvipjECWrEXJS',
        externalMappingId: '0djSuFqSFEaEDErNxUgPpA',
        serviceProviderRoles: [],
        buildingIds: [],
        name: '1ndrew Sullivan',
        companyName: '',
        emailAddress: 'andrew.sullivan@calicoenergy.com',
        emailVerified: true,
        phoneNumber: '',
        lastLoginDate: '2022-01-14T16:29:08Z',
        lastAgreedToTermsDate: '2021-12-20T17:35:37.638Z',
        roles: ['operationalAdmin'],
        isEspmConnected: true,
        espmPropertyCount: 1,
    },
};

export default [
    rest.get('/api/users', (_req, res, ctx) =>
        res(
            ctx.json({
                data: Object.values(mockUsers).map(({ espmPropertyCount: _omit, ...rest }) => rest), // omit property count from listing, since it's only on individual user resource
            })
        )
    ),
    rest.get('/api/users/:userId', async (req, res, ctx) => {
        const { userId } = req.params as { userId: keyof typeof mockUsers };
        await new Promise((res) => setTimeout(res, 500));
        return res(ctx.json({ data: mockUsers[userId] }));
    }),
    rest.get('/api/current-user', async (_req, res, ctx) =>
        res(
            ctx.json({
                data: mockUsers['54s3Fq50IJvipjECWrEXJS'],
            })
        )
    ),
];
