type Environment = 'local' | 'dev' | 'staging' | 'ccb' | 'prod';
type Tenant = string;

function inferEnvFromHostname(hostname: string): Environment {
    const envMatchers: Record<Environment, (hostname: string) => boolean> = {
        local: (hostname) => hostname.endsWith('localhost'),
        dev: (hostname) =>
            hostname.startsWith('dev') ||
            (hostname.endsWith('dev') && !hostname.startsWith('demo')),
        staging: (hostname) => hostname.startsWith('test') || hostname.startsWith('staging'),
        ccb: (hostname) => hostname.startsWith('ccb'),
        prod: () => true,
    };
    return (Object.keys(envMatchers) as Environment[]).find((env) => envMatchers[env](hostname))!; // because we fall back to prod, this assert is safe
}

function inferTenantFromHostname(hostname: string, env: Environment): Tenant | null {
    const tenantMatchers: Record<Environment, RegExp> = {
        local: /(\w*)\.wbbi\.localhost/,
        dev: /dev.*\.(\w*)\.com/,
        staging: /(?:staging|test).*\.(\w*)\.com/,
        ccb: /ccb.*\.(\w*)\.com/,
        prod: /(\w*)\.com/,
    };
    const match = hostname.match(tenantMatchers[env]);

    // if env = local or dev, we can fall back to default, otherwise we return null and throw later
    return ['local', 'dev'].includes(env) ? (match ? match[1] : 'default') : match && match[1];
}

function translateClientToTenant(client: string) {
    switch (client) {
        case 'euds':
            return 'energyusagedata';
        case 'eu':
            return 'exelonenergyusagedata';
        case 'dte':
            return 'dteenergy';
    }
    return '';
}

export function inferInstanceFromHostname(hostname: string): [Tenant, Environment] {
    // special cases from infra1
    if (hostname === 'demo.wbbi.dev') {
        return ['demo', 'prod'];
    } else if (hostname === 'staging.wbbi.dev') {
        return ['default', 'dev'];
    }
    if (hostname.endsWith('.wbbi.dev')) {
        const startHost = hostname.replace('.wbbi.dev', '');
        if (startHost.startsWith('dev-')) {
            return [translateClientToTenant(startHost.replace('dev-', '')), 'dev'];
        } else if (startHost.startsWith('staging-')) {
            return [translateClientToTenant(startHost.replace('staging-', '')), 'staging'];
        } else if (startHost.startsWith('prod-')) {
            return [translateClientToTenant(startHost.replace('prod-', '')), 'prod'];
        }
    }

    const env = inferEnvFromHostname(hostname);
    const tenant = inferTenantFromHostname(hostname, env);

    if (!env || !tenant) {
        throw new Error('Could not match hostname: ' + hostname);
    }

    return [tenant, env];
}

export function injectScript(path: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = path;
    script.async = false;
    script.onerror = () => console.error('Error loading tenant config:', path);
    document.head.appendChild(script);
}
