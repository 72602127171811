import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-droid-sans-mono';
import 'typeface-lato';

import './index.css';
import { inferInstanceFromHostname, injectScript } from 'core/config-loader';

if (process.env.REACT_APP_MOCK_REQUESTS) {
    const { worker } = require('./devServer');
    worker.start({ onUnhandledRequest: 'bypass' });
}

const { hostname } = window.location;
const [tenant, env] = inferInstanceFromHostname(hostname);

injectScript(`/config/${tenant}-${env}.js`);

async function renderApp() {
    // because dynamically injected tenant config scripts run *after* statically injected (via
    // webpack) scripts (eps. when the static scripts are in browser cache), we use this kludge
    // to ensure we import App *after* config is ready (otherwise importing App will throw, as
    // several nested imports require window.config + window.env)

    if (window.config) {
        const app = await import('./App');
        const App = app.default;
        ReactDOM.render(<App />, document.getElementById('root'));
    } else {
        setTimeout(renderApp, 0);
    }
}
renderApp();
